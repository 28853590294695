.yellowbox.registermain input {
    background: #f7f7f7 none repeat scroll 0 0;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    font-size: 14px;
    height: 50px;
    line-height: 50px;
    width: 100%;
    margin: 0 0 10px 0;
}


.registermain  .sec-box span {
    font-size: 32px;
    margin: 20px 0 5px 0;
    font-weight: 600;
    color: #000;
    line-height: 50px;
    text-align: left;
    display: block;
}

.registermain  .sec-box p {
    font-size: 16px;
    font-weight: 400;
    margin: 0px 0 0 0;
    text-align: left;
    color: #000;
    padding: 0 0px;
}

.bigsecinput {
    margin: 40px 0 40px 0;
    display: block;
    width: 100%;
}


button.apply-now.btn.btn-info.savebtn {
    padding: 10px 25px;
    border: none;
    color: #fff;
    position: relative;
    background-color: #0061FE;
    border-radius: 11px;
    font-size: 21px;
    margin: 20px auto 0;
}



.yellowbox.registermain select {
    background: #f7f7f7 none repeat scroll 0 0;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    font-size: 14px;
    height: 50px;
    width: 100%;
  margin: 10px 0 0px 0;

}


.form-group.btninfo a {
  display: block;
  height: 17px;
}

/*header.registerheader {
    background: rgb(0 0 0 / 36%);
}*/

.modal-content.popcontent {
    border-radius: 0;
    border: 0;
    position: relative;
    border-radius: 25px;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.modal-content.popcontent .btn-close {
    position: absolute;
   /* background-color: #FFF;*/
    opacity: 1;
    width: 30px;
    height: 30px;
    border-radius: 60px;
    right: -43px;
    top: 0px;
    line-height: 30px;
    font-size: 11px;
    font-weight: bold;
}

.popcontentinn .sec-box h5 {
  /*  font-size: 32px;
    margin: 10px 0 5px 0;
    color: #000;
    line-height: 50px;
    text-align: center;
    display: block;
    FONT-WEIGHT: 600;*/

    font-size: 40px;
margin: 10px 0 5px 0;
color: #000;
line-height: 50px;
text-align: center;
display: block;
background: #82B7E5;
background: linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
  background-clip: border-box;
background-clip: border-box;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-family: "Lufga-Bold";
}

.sec-box p {
    font-size: 16px;
    font-weight: 400;
    margin: 0px 0 20px 0;
    text-align: center;
    color: #000;
    padding: 0px;
}


.bigsecinputnew {
    width: 100%;
    padding: 0px 40px 10px 40px;
    margin-top: 10px;
    border-radius: 0;z-index: 99;
position: relative;
}

.bigsecinputnew input {
    background: #f7f7f7 none repeat scroll 0 0;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    font-size: 15px;
    height: 50px;
    line-height: 50px;
    margin: 0 0 10px 0;
}

/*.bigsecinputnew p a {
    color: #0062fe;
}
*/
button.btn.btn-primary.applybtn {
    padding: 15px 25px;
    border: none;
    color: #fff;
    position: relative;
    background-color: #0061FE;
    font-size: 21px;
    display: block;
    text-align: center;
    margin: 0px auto 20px;
    border-radius: 11px;
}




.loading-background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    cursor: progress;
}






.section-boddymodel {
  display: flex;
  width: 100%;
}

.section-boddymodelleft {
  margin: 0 18px 0 0;
  width: 50%;
  float: left;
}


.section-boddymodelleft img {
  width: 100%;
}

.section-boddymodelright {
  width: 60%;
}


.section-boddymodelright h1 {
  margin: 0 0 0 0;
  font-size: 18px;
  color: #000;
  padding-bottom: 8px;
}

.section-boddymodelright p {
  font-size: 13px;
  color: #000;
  margin: 0 0 6px 0;line-height: 20px;
}



#close_btn_registerone {
  position: absolute;
  background-color: #FFF;
  opacity: 1;
  width: 30px;
  height: 30px;
  border-radius: 60px;
  line-height: 30px;
  font-size: 11px;
  font-weight: bold;
  bottom: -20%;
  left: 16.5vw;
}




.logingraytop {
  background-color: #ECF1F8;
  width: 100%;
  padding: 110px 0px 100px;
  position: relative;
  z-index: 1;
  background: linear-gradient(103.88deg, rgba(11, 174, 255, 0.5) 0.08%, rgba(0, 97, 252, 0.5) 100.33%);
box-shadow: 20px 20px 50px 0px #0000001C;


}

.logingrayinn {
  margin: 0px 0 0 0;
  background-color: #FFF;
  box-shadow: 10px 10px 35px 0px #00000024;
  border-radius: 33px;
  padding: 40px;
}


.logingrayinn input {
  background: #f7f7f7 none repeat scroll 0 0;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  margin: 20px 0 0px 0;
}


#loginbtnn {
color: #FFF;
background: linear-gradient(102.66deg, #78CEFF -17.72%, #0295FA 80.89%);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 22px;
-webkit-backdrop-filter: blur(42px);
backdrop-filter: blur(42px);
display: inline-block;
font-size: 18px;
font-family: 'Lufga-Bold';
line-height: normal;
text-align: center;
outline: none;
padding: 10px 30px !important;
border: 1px solid transparent;
transition: all .5s ease !important;
-webkit-transition: all .5s ease;
-o-transition: all .5s ease !important;
width: auto;
}


.forgotbtn {
  margin: 10px 0 10px 0;
  display: block;
}




.walletmain {
  width: 100%;
  padding: 110px 0px 100px;
  position: relative;
  z-index: 1;
  background: linear-gradient(103.88deg, rgba(11, 174, 255, 0.5) 0.08%, rgba(0, 97, 252, 0.5) 100.33%);
  box-shadow: 20px 20px 50px 0px #0000001C;
}





.table-title {
   display: block;
  margin: auto;
  max-width: 600px;
  padding:5px;
  width: 100%;
}

.table-title h3 {
   color: #fafafa;
   font-size: 30px;
   font-weight: 400;
   font-style:normal;
   font-family: "Roboto", helvetica, arial, sans-serif;
   text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
   text-transform:uppercase;
}


/*** Table Styles **/

.table-fill {
 padding: 0px;
width: 100%;
}
 
th {
  color:#D5DDE5;;
  background:#1b1e24;
  border-bottom:4px solid #9ea7af;
  border-right: 1px solid #343a45;
  font-size:23px;
  font-weight: 100;
  padding:24px;
  text-align:left;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  vertical-align:middle;
}

th:first-child {
  border-top-left-radius:3px;
}
 
th:last-child {
  border-top-right-radius:3px;
  border-right:none;
}
  
tr {
  border-top: 1px solid #C1C3D1;
  border-bottom-: 1px solid #C1C3D1;
  color:#666B85;
  font-size:16px;
  font-weight:normal;
  text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
}
 

 
tr:first-child {
  border-top:none;
}

tr:last-child {
  border-bottom:none;
}
 
/*tr:nth-child(odd) td {
  background:#EBEBEB;
}*/

tr:last-child td:first-child {
  border-bottom-left-radius:3px;
}
 
tr:last-child td:last-child {
  border-bottom-right-radius:3px;
}
 
td {
  background:#FFFFFF;
  padding:20px;
  text-align:left;
  vertical-align:middle;
  font-weight:300;
  font-size:18px;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
/*  border-right: 1px solid #C1C3D1;*/
}

td:last-child {
  border-right: 0px;
}

th.text-left {
  text-align: left;
}

th.text-center {
  text-align: center;
}

th.text-right {
  text-align: right;
}

td.text-left {
  text-align: left;
}

td.text-center {
  text-align: center;
}

td.text-right {
  text-align: right;
}


.code h2 {
  font-size: 23px;
  margin: 0 0 15px 0;
  color: #222;
}
.code {
 text-align: center;
color: #fff;
background: #fff;
padding: 50px 13px;
border-radius: 10px;
}


.code span {
font-size: 18px;
color: #222;
}

.footer-social.codelsocial {
  text-align: center;
  margin: 0 auto;
  display: block;
}




.footer-social.codelsocial li a {
 background: #55bdfd;
background: linear-gradient(to right, #55bdfd 0%,#0095f8 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#55bdfd', endColorstr='#0095f8',GradientType=1 );
  width: 40px !important;
  height: 40px !important;
  display: block;
  border-radius: 100px;
  line-height: 55px;
}


.code a {
  font-size: 18px;color: #A020F0;
}


.code p {
  font-size: 18px;
  color: #222;margin: 10px 0;
}

.footer-social li a img {
  width: 25px;
}


.footer-social.codelsocial {
  text-align: center;
  margin: 0 auto;
  display: block;
}

.footer-social.codelsocial li {
  display: inline-block;
}


#v-pills-tab .nav-link.active {
  border-radius: 15px;

}


#v-pills-tab .nav-link {
  padding: 10px 15px; color: #FFF; display: flex;
align-items: center;
  margin: 0px 0 10px 0;text-align: left;
/*  background: #FFF;
  border-radius: 40px;*/font-size: 16px;
}

#v-pills-tab .nav-link img {
  float: left;
  margin: 0 10px 0 0;
}


#v-pills-tab .nav-link.active {
 /* color: #fff;
  background-color: #0d6efd !important;*/
}


#applyForm input {
  background: #f7f7f7 none repeat scroll 0 0;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  margin: 10px 0 0px 0;
}


 #applyForm select {
  background: #f7f7f7 none repeat scroll 0 0;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  font-size: 14px;
  height: 50px;

}


#applyForm {
  background: #fff;
  padding: 13px 0;
  border-radius: 10px;
}


button.btn.btn-primary.applybtn {
  padding: 7px 25px;
  border: none;
  color: #fff;
  position: relative;
  font-size: 21px;
  display: block;
  margin: 0px auto 20px;
  color: #FFF;
  background: linear-gradient(102.66deg, #78CEFF -17.72%, #0295FA 80.89%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  -webkit-backdrop-filter: blur(42px);
  backdrop-filter: blur(42px);
}


.reginput  {
position: relative !important;
border-radius: 7px !important;
background: #FFF !important;

}


.inputboxupp *:not(span) {
    position: relative;
    display: inherit;
    border-radius: inherit;
    margin: 0;
    border: none;
    outline: none;
    padding: 0 0.325em;
    z-index: 1;
}



.inputboxupp {
   position: relative;
font-size: 1.5em;
background: linear-gradient(to right, rgb(235 74 38 / 44%) 40%,rgb(164 23 212 / 44%) 50%,rgb(0 70 184 / 44%) 62%);
padding: 3px;
border-radius: 10px;
width: 70%;
margin: 20px auto 0;
display: flex;
}

.text-danger.erroremail {
/*  width: 70%;
  margin: 0 auto;*/
  float: left;
}

.inputboxupp.createboxxupp {
  float: left;
  margin: 0;width: 100%;
}

.form-group.create-wallmain {
  display: flex;
}




.modal-content.popcontent.lightbg::after{
  content: "";
  position: absolute;
  top: 0px;
   background-image: url("assets/images/light-logo.png");
width: 40%;
height: 100%;
background-size: cover;
right: 0px;
background-repeat: no-repeat;
z-index: 0;
opacity: 0.09;

}


.twobtnwallet {
  padding: 10px 0 0 0;
  border-top: 1px solid #ddd;
  margin: 15px 0 0 0;text-align: center;
}

.twobtnwallet h3 {
  font-size: 22px;
  margin: 0px 0 0px 0;
  color: #000;
  line-height: 50px;
  text-align: center;
  display: block;
  background: #82B7E5;
  background: linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
    background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Lufga-Bold";
}



.metamstbtnupor {
margin: 0 5px;
background: linear-gradient(102.66deg, #78CEFF -17.72%, #0295FA 80.89%);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
color: #FFF;
padding: 0px;
border-radius: 30px;
width: 34px;
height: 34px;
display: inline-block;
line-height: 34px;

}


.tableback {
 /* background: #fff;*/
  padding: 13px;
  border-radius: 10px;
}

.profileapply {
  display: flex;
  flex-wrap: wrap;

}






.slick-track,
.slick-list {
  height: 100vh;
}

.slick-dotted.slick-slider {
  margin: 0;
}

.slides::-webkit-scrollbar {
    display: none;
}


.slides::-moz-scrollbar {
    display: none;
}

.slides {
  width: 100%;
  height: 100vh;overflow-x: hidden;
}
.slides .slide {
  color: #419be0;
  background: white;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
}

.slick-dots {
  position: absolute;
  bottom: 20px;
  left: 20px;
  text-align: left;
}
.slick-dots li {
  display: block;
}




.round-blue {
  text-align: center;
padding: 5px;
width: 40px;
border-radius: 100px;
height: 40px;
line-height: 40px;margin: 0 auto;
background: #55bdfd;
background: linear-gradient(to right, #55bdfd 0%,#0095f8 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#55bdfd', endColorstr='#0095f8',GradientType=1 );
}


.table-hover input, .table-hover select {
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  font-size: 14px;
  height: 50px;
}

.copytxt {
  display: inline-block !important;
width: 30px;
height: 30px;
text-align: center;
margin: 0 0 0 10px;
border-radius: 90px;
padding: 5px;
background: #55bdfd;
background: linear-gradient(to right, #55bdfd 0%,#0095f8 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#55bdfd', endColorstr='#0095f8',GradientType=1 );
cursor: pointer;
position: absolute;
top: 18px;
right: -20px;
}


.round-blue img {
  width: 75%;
}

.profilebtnbox {
  margin: 10px 0 0 0;
}



















.snip1568 {
  font-family: 'Open Sans', Arial, sans-serif;
  position: relative;
  display: inline-block;
  margin:0px 0px 50px 0;
 min-width: 311px;
max-width: 349px;
  width: 100%;
  color: #141414;
  text-align: left;
  line-height: 1.4em;
  font-size: 14px;
  box-shadow: none !important;
}

.snip1568 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.snip1568 .profile-image img {
  border-radius: 5px;
  max-width: 100%;
  height: 80px;
  vertical-align: top;
  float: left;
}

.snip1568 figcaption {
 width: 100%;
color: #000;
display: inline-block;
margin-bottom: 15px;
position: relative;
background: #fff;
padding: 13px 13px;
border-radius: 10px;height: 140px;
box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.snip1568 figcaption:after {
  content: '';
  position: absolute;
  left: 55px;
  top: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;

  border-color: #fff transparent transparent transparent;
}

.snip1568 h3,
.snip1568 h4,
.snip1568 p {
  margin: 0 0 5px;
}

.snip1568 h3 {
 background: #82B7E5;
background: linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
  background-clip: border-box;
background-clip: border-box;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin: 0 0 10px 0;
font-size: 26px;
color: #000;
font-family: "Lufga-Bold";
display: block;
text-align: left;
}

.cont .grid {
   display: inline-block;
width: 100%;
}


 .cont .grid .col {
  background: #E2E2E3;
height: 30px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 56px;
color: #585A5F;
padding: 0 10px;
cursor: pointer;
font-size: 12px;
font-family: 'Lufga-Bold';
float: left;
margin: 0 4px 10px 0px;
}




.snip1568 i:hover {
  opacity: 1;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

/* Demo purposes only */



.starter-template h4 {
  color: #020202;
  padding-bottom: 10px;
  font-size: 38px;
  font-weight: 600;
}
.list-unstyled.user-cards {
 list-style: none;
margin: 20px 0 50px 0;
border-bottom: 1px solid #fff;
padding: 0px 0 50px 0;
}

      .starter-template {
            padding: 10px 0px;
            text-align: center;
        }

.user-cards li {
  
    margin-bottom: 1em;
    border-radius: 4px
}

.user-cards a {
    display: block;
    padding: 2em;
    text-align: center;padding: 13px 13px;
border-radius: 10px;
}

.user-cards .user-card-intro {
    height: 128px
}

.user-cards h3 {
   font-size: 22px;
color: #000;
font-family: "Lufga-Bold";
}

.user-cards p {
    color: #111;
    opacity: .5;
    margin: .8em 0;
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

.user-cards svg {
    max-width: 260px
}

.user-cards li.user-card-designer .card-link {
    color: #608cee
}

.user-cards li.user-card-developer .card-link {
    color: #fbb359
}

@media only screen and (min-width:480px) {
    .user-cards p,
    .user-cards .card-link {
        font-size: 1.8rem
    }
}

@media only screen and (min-width:800px) {
    .user-cards::before,
    .user-cards::after {
        content: "";
        display: table
    }

    .user-cards::after {
        clear: both
    }

    .user-cards li {
        width: 32%;
        float: left;
        margin: 0 2% 0 0
    }

    .user-cards li:nth-of-type(3) {
        margin-right: 0
    }

    .user-cards p,
    .user-cards .card-link {
        font-size: 1.6rem
    }
}

@media only screen and (min-width:1024px) {
    .user-cards .user-card-intro {
        height: 140px
    }


}

@media only screen and (min-width:1280px) {
   
    .user-cards a {
        overflow: hidden;
        position: relative;
        -webkit-transition: -webkit-box-shadow .3s;
        transition: -webkit-box-shadow .3s;
        transition: box-shadow .3s;
        transition: box-shadow .3s, -webkit-box-shadow .3s
    }

    .user-cards .user-card-intro,
    .user-cards .user-svg-wrapper {
        z-index: 2;
        position: relative
    }

    .user-cards .user-card-intro {
        height:80px
    }

    .user-cards .user-svg-wrapper svg #svg-circle {
        display: none
    }

    .user-cards .user-svg-wrapper svg #des-mug,
    .user-cards .user-svg-wrapper svg #des-bubble,
    .user-cards .user-svg-wrapper svg #des-camera,
    .user-cards .user-svg-wrapper svg #team-user-right,
    .user-cards .user-svg-wrapper svg #team-user-left,
    .user-cards .user-svg-wrapper svg #dev-mug,
    .user-cards .user-svg-wrapper svg #dev-books,
    .user-cards .user-svg-wrapper svg #dev-bubble {
        -webkit-transition: -webkit-transform .3s;
        transition: -webkit-transform .3s;
        transition: transform .3s;
        transition: transform .3s, -webkit-transform .3s
    }

    .user-cards .card-link {
        height: 22px;
        position: relative;
        overflow: hidden
    }

    .user-cards .card-link::after,
    .user-cards .card-link span {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        -webkit-transition: top .3s;
        transition: top .3s
    }

    .user-cards .card-link::after {
        content: 'Explore';
        top: 100%;
        color: #fff
    }

    .user-cards .card-link span {
        top: 0
    }

    .user-cards .user-card-circle {
        position: absolute;
        width: 170px;
        height: 170px;
        bottom: 34px;
        left: calc(50% - 85px);
        border-radius: 50%;
        will-change: transform, background;
        -webkit-transition: background .3s, -webkit-transform .3s;
        transition: background .3s, -webkit-transform .3s;
        transition: transform .3s, background .3s;
        transition: transform .3s, background .3s, -webkit-transform .3s;
        z-index: 1;opacity: 0;
    }

    .user-cards li.user-card-designer .user-card-circle {
        background: #fff
    }

    .user-cards li.user-card-team .user-card-circle {
        background: #fff
    }

    .user-cards li.user-card-developer .user-card-circle {
        background: #fff
    }

    .user-cards a:hover {
        -webkit-transition: -webkit-box-shadow .5s;
        transition: -webkit-box-shadow .5s;
        transition: box-shadow .5s;
        transition: box-shadow .5s, -webkit-box-shadow .5s
    }

    .user-cards a:hover p {
        opacity: 1
    }

    .user-cards a:hover .card-link::after {
        top: 0
    }

    .user-cards a:hover .card-link span {
        top: -100%
    }

    .user-cards a:hover .user-card-circle {
        -webkit-transform: scale(4.5);
        transform: scale(4.5);
        opacity: 1;
    }

    .user-cards a:hover #des-mug,
    .user-cards a:hover #team-user-left,
    .user-cards a:hover #dev-mug {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px)
    }

    .user-cards a:hover #des-camera,
    .user-cards a:hover #team-user-right,
    .user-cards a:hover #dev-books {
        -webkit-transform: translateX(5px);
        transform: translateX(5px)
    }

    .user-cards a:hover #des-bubble,
    .user-cards a:hover #dev-bubble {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px)
    }



    .user-cards li.user-card-designer a:hover .user-card-circle {
        background: #fff
    }

    

    .user-cards li.user-card-team a:hover .user-card-circle {
        background: #fff;
    }

   

    .user-cards li.user-card-developer a:hover .user-card-circle {
        background: #fff
    }
}



.user-svg-wrapper img {
  width: 60%;
}

.code.codeonetop {
  margin: 0 0 30px 0;padding:30px 13px;
}


.code h1 {
  font-size: 22px;
  margin: 0 0 15px 0;
  color: #222;
}


.coderight {
  position: relative;
}



.form-group.btninfo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
}

.col-md-12.btninfomainleft {
  width: 93%;
}

.col-md-1.btninfomain {
  width: 4%;
  padding: 0;
}

.form-group.createinfo input {
 width: 92%;
float: left;
margin-right: 15px;
margin-top: 0;
margin-bottom: 10px;
}


.code.earncode h3 {
  color: #000;
  font-weight: 900;
  font-size: 22px;
  font-family: "Lufga-Bold";
  margin: 0 0 13px 0;
}



.form-group.createinfo a img {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  margin: 9px 0;
}




.modal-content.popcontent.succesmainmain {
  border-radius: 10px;position: relative;
}

.modal-body.succesmaininn h5 {
  text-align: center;
  background: #30d577;
  overflow: hidden;
  padding: 20px 0;
  border-radius: 10px 10px 0 0;
  color: #FFF;
  font-size: 24px;
}

.modal-body.succesmaininn {
  padding: 0;
  overflow: hidden;
}


.succesmaininnmid {
  padding: 20px 20px;
}


.modal-content.popcontent.succesmainmain .btn-close {
  background-color: #FFF;
  padding: 5px;
  background-size: 15px 15px;
  position: absolute;
  right: -20px !important;
  top: -20px;
}


.modal-body.succesmaininn.errorred h5 {
  background: #e4505e;
}



.modal-content.popcontent.watchvideomaininn {
  border-radius: 28px;
  margin: 10px;
  border: 6px solid rgba(255, 255, 255, 0.22);
}



.modal-body.popcontentinn.videoinn video {
  border-radius: 20px;
}



.modal-content.popcontent.watchvideomaininn .btn-close {
  font-size: 21px;
  z-index: 99;
}

.sec-box {
  width: 80%;
  margin: 0 auto;
}


.rareassets {
  display: block;
  color: #020202;
  padding-bottom: 0px;
  font-size: 25px;
  font-weight: 400;
  text-align: center;
}

.form-control.reginput::placeholder {
  color: #0295FADE !important;
}

.bigsecinputnew.secbigsecinputnew {
  padding: 0px 0px 10px 0px;
}


.reviewingtxt {
  margin: 0 0 0 0 !important;
  font-size: 16px !important;
}


.bigsecinputnew.secbigsecinputnew p {
  font-size: 14px;
}


.bigsecinputnew.secbigsecinputnew .inputboxupp {
  margin: 30px auto 0;
}


.framebg img {
  width: 100%;
}

.framebg {
  width: 100%;
}

.reviewingtxt {
  margin: 20px 0 0 0 !important;
}

.yellowbox.registermain .sec-box {
  width: auto;
}


.form-group.forgotmain {
  margin: 0 0 10px 0;
}



.bigsecinput.rewardnew {
  background: #fff;
  padding: 13px;
  border-radius: 10px;
}

.noformeffect {
  background: transparent !important;
  padding: 0px !important;
  border-radius: 0px !important;
}

.noformeffect input {
  background: #FFF !important;
  border-radius: 8px !important;
  border: transparent !important;
  margin-top:0px !important;
}


.noformeffect .inputboxupp {
  margin: 0;
  width: auto;
}

.noformeffect .form-group {
  margin: 0 0 10px 0;
}


.noformeffect select {
  margin: 0px !important;
  background: #FFF !important;
  border: transparent !important;
  border-radius: 8px !important;
}


.rewardleft img {
  width: 100%;
}


.rewardleft {
  width: 100%;
}

.noformeffect input::placeholder {
  color: #0295FADE !important;
}


.noformeffect button {
  padding: 10px 35px !important;
  border: none;
  position: relative;
  font-size: 21px!important;
  display: block;
  margin: 20px 0 10px!important;
  color: #FFF;
  background: linear-gradient(102.66deg, #78CEFF -17.72%, #0295FA 80.89%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px!important;
  -webkit-backdrop-filter: blur(42px);
  backdrop-filter: blur(42px);
}




.leftprofile {
  height:80vh;
  background: #48aadf;
  background: -moz-linear-gradient(top, #48aadf 0%, #2c8ccb 100%);
  background: -webkit-linear-gradient(top, #48aadf 0%,#2c8ccb 100%);
  background: linear-gradient(to bottom, #48aadf 0%,#2c8ccb 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#48aadf', endColorstr='#2c8ccb',GradientType=0 );
  border-radius: 20px;
  position: relative;
}

.nav-link.bottomlogout {
  position: absolute;
  bottom: 0;
  left: 0;
}


.nav-pills.leftprofilebtninn .nav-link.active {
  background: #55bdfd;
  background: -moz-linear-gradient(left, #55bdfd 0%, #0095f8 100%);
  background: -webkit-linear-gradient(left, #55bdfd 0%,#0095f8 100%);
  background: linear-gradient(to right, #55bdfd 0%,#0095f8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#55bdfd', endColorstr='#0095f8',GradientType=1 );
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.user-newpro {
  color: #FFF;
  font-size: 24px;
  padding: 15px;
  margin: 0 0 10px 0;
}


.leftprofilebtninn .nav-link i {
  position: absolute;
  right: 0;
  visibility: hidden;
}


.nav-pills.leftprofilebtninn .nav-link.active i {
  visibility: visible;
}

.table-hover.nocolor_table tr td {
 background: transparent;
  border: 0px;padding: 10px;
}


.table-hover.nocolor_table tr {
  border-radius: 10px;
  background: #FFF;
}

.code.codeonetop h2 {
  background: #82B7E5;
  background: linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 20px 0;
  font-size: 48px;
  color: #000;
  font-family: "Lufga-Bold";
  display: block;
  text-align: center;
}



#trailerVideo {
  border-radius: 20px;
}


.code.earncode {
  text-align: left;
  padding: 25px;
}

.code.earncode h1 {
  text-transform: inherit;
}



.reffcode {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.reffcode li {
  display: block;
  margin: 0 0 20px 0;
}


.codeleft {
  float: left;
  margin: 0px 10px 0 0;
  width: 50px;
}

.codeleft img {
  width: 100%;
}



.coderight span {
  display: block;
  font-weight: 900;
  font-family: 'Lufga-Bold';font-size: 16px;
}


.coderight a {
  font-size: 12px;
}

.coderight h2 {
  font-size: 12px;
}

.code.earncode .footer-social.codelsocial {
  padding: 0;
}

.code.earncode:last-child {
  margin: 20px 0 0 0;
}


.banefits-box.banefits-box-type-2.earn-bane {
  height: 323px;
}


.code.codeonetop h5 {
  background: #82B7E5;
  background: linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 20px 0;
  font-size: 48px;
  color: #000;
  font-family: "Lufga-Bold";
  display: block;
}


.codeonetopinn {
  background: #FFF;
  padding: 30px 13px;
  border-radius: 8px;
}

.code.codeonetop.newtopcode {
  margin: 0 0 30px 0;
  position: relative;
  background: linear-gradient(to right, rgb(235 74 38 / 44%) 40%,rgb(164 23 212 / 44%) 50%,rgb(0 70 184 / 44%) 62%);
  padding: 2px;
  width: 100%;
}