
@charset "utf-8";

/*====================================
            Root Variable
=====================================*/

:root {
    --main-selection-color: #FFF;
    --main-selection-bg: #454545;
    --input-placeholder-color: #898989;
    --main-font: 'Lufga-Regular';
}

/*====================================
    Fonts 
=====================================*/

@font-face {
    font-family: 'Lufga-Medium';
    src: url('../fonts/Lufga-Medium.eot');
    src: url('../fonts/Lufga-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lufga-Medium.woff2') format('woff2'),
        url('../fonts/Lufga-Medium.woff') format('woff'),
        url('../fonts/Lufga-Medium.ttf') format('truetype'),
        url('../fonts/Lufga-Medium.svg#Lufga-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga-Regular';
    src: url('../fonts/Lufga-Regular.eot');
    src: url('../fonts/Lufga-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lufga-Regular.woff2') format('woff2'),
        url('../fonts/Lufga-Regular.woff') format('woff'),
        url('../fonts/Lufga-Regular.ttf') format('truetype'),
        url('../fonts/Lufga-Regular.svg#Lufga-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga-Bold';
    src: url('../fonts/Lufga-Bold.eot');
    src: url('../fonts/Lufga-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lufga-Bold.woff2') format('woff2'),
        url('../fonts/Lufga-Bold.woff') format('woff'),
        url('../fonts/Lufga-Bold.ttf') format('truetype'),
        url('../fonts/Lufga-Bold.svg#Lufga-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* ====================================
    Common Css 
=====================================*/

/*RESET*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

thead {
    font-weight: 600;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body{

  margin: 0px;
}

ol,
ul {
    list-style-type: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

img {
    max-width: 100%;
    height: auto;
}

*:focus,
select:focus,
.custom-select:focus,
button:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
    outline: none !important;
    box-shadow: none;
}

input:focus::-moz-placeholder {
    opacity: 0;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s
}

body {
    width: 100%;
    font-size: 14px;
    line-height: 1.5;
    font-family: var(--main-font);
    font-weight: normal;
    font-style: normal;
    text-rendering: auto;
    color: #222;
    background-color: #FFF;
    overflow-x: hidden;
    text-align: left;text-align: left;
padding: 0px !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    outline: none;
}

*:focus {
    outline: none;
}

img {
    max-width: 100%;
}

a {
    color: inherit;
    transition: all ease .4s;
    text-decoration: none;
}

a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
    line-height: 1.2;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: #222;
}


iframe {
    display: inline-block;
    vertical-align: middle;
   
    border: none;
}

video {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    border: none;
}

::selection {
    background: var(--main-selection-bg);
    color: var(--main-selection-color);
}

::-webkit-selection {
    background: var(--main-selection-bg);
    color: var(--main-selection-color);
}

::-moz-selection {
    background: var(--main-selection-bg);
    color: var(--main-selection-color);
}

::-o-selection {
    background: var(--main-selection-bg);
    color: var(--main-selection-color);
}

::-ms-selection {
    background: var(--main-selection-bg);
    color: var(--main-selection-color);
}

/*PlaceHolder*/
::-webkit-input-placeholder {
    color: var(--input-placeholder-color) !important;
    opacity: 1;
}

::-moz-placeholder {
    color: var(--input-placeholder-color) !important;
    opacity: 1;
}

:-ms-input-placeholder {
    color: var(--input-placeholder-color) !important;
    opacity: 1;
}

:-moz-placeholder {
    color: var(--input-placeholder-color) !important;
    opacity: 1;
}


/*===Button CSS=====*/

.button {
    display: inline-block;
    font-size: 18px;
    font-family: 'Lufga-Bold';
    line-height: normal;
    text-align: center;
    outline: none;
    border-radius: 4px;
    transition: all 500ms ease;
    padding: 0 20px;
    padding-top: 9px;
    padding-bottom: 10px;
    border: 1px solid transparent;
    transition: all .5s ease !important;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease !important;
}

a.button {
    cursor: pointer;
}


/*===Button Bg===*/

.button.button-blue {
    color: #FFF;
    background: linear-gradient(102.66deg, #78CEFF -17.72%, #0295FA 80.89%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 22px;
    backdrop-filter: blur(42px);
}

.button.button-blue:hover, .button.button-blue:focus{
    color: #FFF;
    background: linear-gradient(102.66deg, #51087E -17.72%, #A020F0 80.89%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 22px;
    backdrop-filter: blur(42px);
}

/*===========Landing Page CSS Start Here=============*/

header{
    width: 100%;
    position: fixed;
    left: 0;
    top:0px;
    padding:10px 0;
    z-index: 99;
}
header .container{
   
  /*  justify-content: space-between; display: flex;*/
    align-items: center;
}

.registerheader.sticky {
  background: rgba(255, 255, 255, 0.99);
}

.whitelogo {
  display:block;
}


.blacklogo {
  display: none;
}



.registerheader.sticky .main-navigation ul li a {
  color: #000;
}


.registerheader.sticky .button-file {
  color: #000;
}



.registerheader.sticky .blacklogo {
  display: block;
}

.registerheader.sticky .whitelogo {
  display: none;
}




.main-navigation ul{
    display: flex;
}

.main-navigation ul li{
    padding: 0 15px;
}

.main-navigation ul li a{
    display: block;
    font-size: 18px;
    line-height: 26px;
    color: #FFF;
    text-decoration: none;
    padding: 10px 5px;
}

.hero-bannner{
    width: 100%;
    height:100vh;
    background-image: url("../images/hero-banner-1.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
width: 100%;
align-items: center;
justify-content: center;
margin: 0 auto;
}

.banner-text{
    width: 100%;
 /*   max-width: 582px;*/
}
.banner-text h1{
   /* font-size: 80px;
    color: #fff;
    line-height: 80px;
    font-family: 'Lufga-Bold';
    padding-bottom: 30px;*/

    font-size: 45px;
color: #fff;
line-height: 46px;
font-family: 'Lufga-Bold';
padding-bottom: 20px;
}

.metalivingleft {
  text-align: left;
  position: relative;
}


.newbenifit {
  display: none;
}

.metalivingright.showcube {
  display: none;
}



.topheader {
  margin: 0 auto;
  width: 80%;
}


.topheaderone {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.header-right .joinbtntop{
    font-size: 18px;
    color: #FFF;
    height: 43px;
    line-height: 44px;
    padding: 0 15px;
    background-color: transparent;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 2px;
    
    border-image-source: radial-gradient(98.46% 98.46% at 50.75% 1.54%, rgba(235, 74, 38, 0.38) 0%, rgba(164, 23, 212, 0.38) 40.82%, rgba(0, 70, 184, 0.38) 100%);
    border-radius: 22px;
}


.button-file {
 position: relative;
overflow: hidden;
color: #fff;
z-index: 0;
padding:11px 40px;margin: 0 5px;
cursor: pointer;font-size: 18px;
}

.button-file i {
  content: '';
position: absolute;
z-index: -1;
left: 0;
top: 0;
right: 0;
bottom: 0;
border-radius: 30px;
padding: 3px;
-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0) padding-box;
  mask-composite: add, add;
-webkit-mask-composite: destination-out;
mask-composite: exclude
}

.button-file i::before {
/*  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 400px;
  height: 400px;
  background: linear-gradient(#44a0b0 50%, #eeb450 0);
  transform: translate(-50%, -50%) rotate(45deg);
  transition: transform .7s linear;*/

content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 300px;
    height: 240px;
    transform: translate(-50%, -50%) rotate(95deg);
    transition: transform .7s linear;
    background: #eb4a26;
    background: linear-gradient(to right, rgb(235 74 38 / 44%) 40%,rgb(164 23 212 / 44%) 50%,rgb(0 70 184 / 44%) 62%);
}



.button-file:hover i::before {
  transform: translate(-50%, -50%) rotate(315deg);
}














.play-btn{
    font-size: 24px;
    font-family: 'Lufga-Medium';
    line-height: 34px;
    color: #FFF;
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    border: none;position: relative;
z-index: 9;
}

.play-btn img{
    margin-left: 15px;
}

.banner-text .button-blue{
    margin-left: 20px;
}

.cta{
    background-color: #ECF1F8;
    width: 100%;
    padding: 80px 115px 0px;
    position: relative;
    z-index: 1;
}

.cta-container::before{
    content: "";
    width: 100%;
    height: 350px;
    background-image: url("../images/backgrounds/line1.png");
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 0;
    /*bottom: 210px;*/
  	bottom:0px;
	background-size: 100% 90%;
    z-index: 0;
	animation: MoveUpDown 5s linear infinite;
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 100px;
  }
}

/*.frameone {
  width: 100%;
  height: 350px;
  background-image: url("../images/frame-meta.png");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  bottom: 210px;
  z-index: 0;
}
*/




.metalivingleft h3 {
  background: #82B7E5;
  background: linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
    background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 20px 0;
  font-size: 48px;
  color: #000;
  font-family: "Lufga-Bold";
  display: block;
}


.metalivingleft span {
  display: block;
  color: #020202;
  padding-bottom: 10px;
  font-size: 30px;
  font-weight: 500;
}

.metalivingleft p {
  display: block;
  color: #020202;
  font-size: 18px;
  font-weight: 400;
  padding: 0 0px 30px;
}

.metalivingright img {
  width: 100%;
}


.metalivingright {
    animation: swing ease-in-out 1s infinite alternate;
    transform-origin: center -20px;
    float:left;
}

.metalivingright img:after{
    content: '';
    position: absolute;  
    width: 20px; height: 20px;  
    border: 1px solid #999;
    top: -10px; left: 50%;
    z-index: 0;
    border-bottom: none;
    border-right: none;
    transform: rotate(45deg);
}
/* nail */
.metalivingright img:before{
    content: '';
    position: absolute;
    width: 5px; height: 5px;
    top: -14px;left: 54%;
    z-index: 5;
    border-radius: 50% 50%;
    background: #000;
}
 
@keyframes swing {
    0% { transform: rotate(3deg); }
    100% { transform: rotate(-3deg); }
}



.cta-container::after{
    content: "";
    width: 100%;
    height: 350px;
    background-image: url("../images/backgrounds/line2.png");
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 0;
   	bottom:0px;
	background-size: 102%;
    z-index: 0;
	animation: MoveDownUp 5s linear infinite;
}

@keyframes MoveDownUp {
  0%, 100% {
    bottom: 100px;
  }
  50% {
    bottom: 0px;
  }
}

.cta-container{
    background-color: #FFF;
    box-shadow: 10px 10px 35px 0px #00000024;
    border-radius: 33px;
    overflow: visible;

}

.metaliving {
  margin:80px 0 0 0;
  background-color: #FFF;
  box-shadow: 10px 10px 35px 0px #00000024;
  border-radius: 33px;
  padding: 40px;
}


.frameone {
  position: absolute;
  width: 100%;
  z-index: -2;
}

.cta-container .row{
    position: relative;
    z-index: 1;
}



.pyramidmain {
display: flex;
flex-wrap: wrap;
position: absolute;
width: 100%;
bottom: -167px;z-index: -1;

}

.citizensmain img {
  /*width: 60%;*/
	width: 80%;
	margin-left: -10%;
}


.pyramidright img {
 width: 60%;
float: right;
}



.cta-text{
   padding-top: 50px;
padding-bottom: 120px;
padding-left: 0px;text-align: center;
}
.cta-text h4{
    font-size: 42px;
    line-height: 42px;
    color: #000;
    padding-bottom: 60px;
    max-width: 292px;
}

.cta-text h3{
    font-size: 64px;
    line-height: 64px;
    color: #020202;
    padding-bottom: 30px;
   
}


.cta-text h3 {
  color: #020202;
  padding-bottom: 10px;
  font-size: 55px;
  font-weight: 600;
}



.cta-text span {
  display: block;
  color: #020202;
  padding-bottom: 20px;
  font-size: 32px;
  font-weight: 400;
}

.builtmain {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0px 0 20px;
}

.builtmain li {
  margin: 0 12px;
  margin: 0 12px;
display: block;
color: #020202;
font-size: 18px;
font-weight: 400;
}

.builtmain li img {
  width: 100%;
}



.cta-text p{
  display: block;
color: #020202;
font-size: 18px;
font-weight: 400;
padding: 0 70px 30px;
}

.cta-media{
    margin-top: -135px;
    margin-left: -190px;
}
.cta-media img{
    max-width: unset;;
}


.banefits{
    background-color: #ECF1F8;
    padding-top: 80px;
    padding-bottom:80px;
    position: relative;
    /* background-image: url("../../assets/images/backgrounds/bg-rect.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; */
}


.banefits-box{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 36px;
    overflow: hidden;
    text-decoration: none;
    display: block;
    position: relative;
	width: 100%;padding: 0;
border: 0;
	background-size:105%;
  background-position:center center;
-webkit-transition: all 500ms cubic-bezier(0.000, 0.000, 0.580, 1.000); 
   -moz-transition: all 500ms cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -ms-transition: all 500ms cubic-bezier(0.000, 0.000, 0.580, 1.000); 
     -o-transition: all 500ms cubic-bezier(0.000, 0.000, 0.580, 1.000); 
        transition: all 500ms cubic-bezier(0.000, 0.000, 0.580, 1.000); /* ease-out */
}


.banefits-box:hover {
    background-size:125%;
  }



.banefits h1 {
  background: #82B7E5;
  background: linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 30px 0;
  font-size: 48px;
  color: #000;
  font-family: "Lufga-Bold";
  display: block;
  text-align: center;
}

.gallery-text a {
  background: #82B7E5;
  background: linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
    background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size:28px;
  color: #000;
  display: block;
 margin: 60px 0 0 0;
}


.gallery-text a img {
  text-align: right;
  float: right;
  margin: 10px 0;
}



.banefits-box-type-1{
    height:590px;width: 100%;position: relative;
    padding: 20px 30px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.banefits-box.banefits-box-type-1 .box-text {
  position: absolute;
  top: 40px;
  text-align: left;
}



.banefits-box-type-2{
    height: 310px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.banefits-box-type-3{
    height: 260px;
}
.banefits-box h4{
    font-size:22px;
    line-height: 42px;
    color: #FFF;
    padding-bottom: 10px;text-align: left;
}

.banefits-box h2{
    font-size: 42px;
    color: #FFF;
   /* font-family: 'Lufga-Bold';*/
    line-height: auto;
    padding-bottom: 15px;
}

.banefits-box strong {
  font-family: 'Lufga-Bold';
}


.banefits-box h3{
    font-size: 26x;
    line-height: 45px;
    color: #FFF;
    font-family: 'Lufga-Bold';text-align: left;
}

.banefits-box h2 span{
    font-size: 64px;
}
/*.banefits .container{
    max-width: 1544px;
}*/

.left-arrow, .right-arrow{
    display: inline-block;
    width: 40px;margin: 10px 0 0 0;
}

/*
.left-arrow.toparrow {
  right:0px;
  bottom: -50px;
  position: absolute;
}*/


.gallery-item.gallerboxleftbg .gallery-text {
  border-radius: 0 70px 70px 20px;
}

.banefits-box-type-4 h4 {
  position: inherit;
}

.banefits-box-type-4 div {
  margin: 30px 0 0 0 !important;
}

.box-text.lastbanefitsup {
  position: inherit;
  margin: 140px 0 0 0;
  padding-bottom: 0;
}



.left-arrow.side {
 margin: 0px 0 0 0px;
position: absolute;
left: 0;
bottom: -50px;
}


.lastbanefitsinn h3 {
  text-align: right;
}

.banefits-box-type-2 .box-text{
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
}
.gallery-section-title{
    text-align: center;
}
.gallery-section-title img{
    margin-bottom: 30px;
}
.gallery-section-title h4{
    font-size: 36px;
    line-height: 50px;
    color: #000;
    padding-bottom: 60px;
}

.gallery-section-title h2{
    font-size: 64px;
    color: #000;
    padding-bottom: 150px;
}


.gallery-section-title p {
  display: block;
  color: #020202;
  font-size: 18px;
  font-weight: 400;
  max-width: 320px;
  margin: 0 auto 10px;
  line-height: 30px;
}


.networkmain {
  text-align: center;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 30px 0 30px 0;
}


.networkmain li {
  display: block;
  color: #020202;
  padding-bottom: 20px;
  font-size: 58px;
  font-weight: 400;
  margin: 0 15px;
}


.network {
  background: #1F3DA6;
  background: -webkit-linear-gradient(to right, #1F3DA6 0%, #7625b5 100%);
  background: -moz-linear-gradient(to right, #1F3DA6 0%, #7625b5 100%);
  background: linear-gradient(to right, #1F3DA6 0%, #7625b5 100%);
    background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.play {
  background: #8A1FB9;
  background: -webkit-linear-gradient(to right, #8A1FB9 0%, #b117b5 100%);
  background: -moz-linear-gradient(to right, #8A1FB9 0%, #b117b5 100%);
  background: linear-gradient(to right, #8A1FB9 0%, #b117b5 100%);
    background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.learn {
  background: #BC1F9A;
  background: -webkit-linear-gradient(to right, #BC1F9A 0%, #da3550 100%);
  background: -moz-linear-gradient(to right, #BC1F9A 0%, #da3550 100%);
  background: linear-gradient(to right, #BC1F9A 0%, #da3550 100%);
    background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.gallery-item{
    display: flex;
    margin-bottom: 100px;
}
.gallery-item:last-child{
    margin-bottom: 0;
}

.gallery-list .gallery-item:nth-child(even){
    flex-direction: row-reverse;
} 

.gallery-list .gallery-item:nth-child(even) .gallery-text{
    border-radius: 0 70px 70px 0;
}

.gallery-list .gallery-item .gallery-text{
    flex: 0 0 45%;
max-width: 45%;
}


.token {
  padding: 30px 30px;
  background-color: #ECF1F8;
  border-radius: 60px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 80px 0 0 0;
  text-align: center;
}


.token h3 {
  background: #82B7E5;
  background: linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 44px;
  color: #000;
  display: block;
  margin:0px 0 20px 0;
  font-weight: 600;
}

.token p {
  display: block;
  color: #020202;
  font-size: 18px;
  font-weight: 400;
  padding: 0 70px 30px;
}


.token span {
  display: block;
  color: #020202;
  padding-bottom: 10px;
  font-size: 30px;
  font-weight: 400;
}



.progressmain {
 align-items: center;
counter-reset: step;
padding: 0;
width: 50%;
color: #F2F2F2;
list-style: none;
margin: 0 auto 70px;
display: flex;
}
.progress__circle {
  width: 75px;
  height: 75px;
  border: 5px solid #F2F2F2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
}


.progress__bar {
  flex: 1;
  height: 15px;
  background: #fff;
}
.progress--done {
  background: #fff;
}
.progress--active {
 border: 5px solid #fff;
color: #000;
}

.progressmain li span {
  display: block;
  color: #020202;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 0 0 0;
}











.gallery-item .gallery-img{
  flex: 0 0 55%;
max-width: 55%;
height: 520px;
border-radius: 20px;
overflow: hidden;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.gallery-item .gallery-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.gallery-item .gallery-text{
 padding: 40px 100px;
background-color: #ECF1F8;
border-radius: 70px 0 0 70px;
max-height: 400px;
margin-top: 40px;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.gallery-item .gallery-text p{
  line-height: 30px;
display: block;
color: #020202;
font-size: 18px;
font-weight: 400;
margin: 0px 0 0 0;text-align: justify;
}

.gallery-text h2 {
  font-weight: bold;
  font-size: 51px; margin: 0px 0 40px 0;
  font-family: 'Lufga-Bold';
  background: #3437C1;
  background: -webkit-linear-gradient(to right, #3437C1 0%, #881FCF 30%, #BF2B91 61%, #DA3E4F 100%);
  background: -moz-linear-gradient(to right, #3437C1 0%, #881FCF 30%, #BF2B91 61%, #DA3E4F 100%);
  background: linear-gradient(to right, #3437C1 0%, #881FCF 30%, #BF2B91 61%, #DA3E4F 100%);
    background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.gallery-text p span {
  background: #CA3277;
  background: -webkit-linear-gradient(to right, #CA3277 0%, #A81ACA 30%, #6928CA 61%, #0f42bb 100%);
  background: -moz-linear-gradient(to right, #CA3277 0%, #A81ACA 30%, #6928CA 61%, #0f42bb 100%);
  background: linear-gradient(to right, #CA3277 0%, #A81ACA 30%, #6928CA 61%, #0f42bb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-weight: 700;
}




.eco-system{
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #EFF4FC;
}
.eco-system .container{
    background: linear-gradient(103.88deg, rgba(11, 174, 255, 0.5) 0.08%, rgba(0, 97, 252, 0.5) 100.33%);
    box-shadow: 20px 20px 50px 0px #0000001C;
    border-radius: 30px;
    padding: 30px;
}


.partner-list {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
}

.partner-list li{
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 30px;
}

.section-title h4{
    font-size: 54px;
    color: #000;
    font-family: "Lufga-Bold";
    background: #FFFFFF;

    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    display: inline-block;
}

.section-title p{
    font-size: 18px;
    line-height: 24px;
    color: #000;
    padding-bottom: 15px;
    max-width: 500px;
    margin: 0 auto;
} 
.our-team{
    padding-top: 80px;
    padding-bottom: 80px;
}
.our-team .container{
    background: radial-gradient(97.1% 97.1% at 1.9% 2.7%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.1) 100%);
    box-shadow: inset 67.6667px -67.6667px 67.6667px rgba(255, 255, 255, 0.114), inset -67.6667px 67.6667px 67.6667px rgba(255, 255, 255, 0.114);
    border-radius: 10px;
}
.member-box figcaption{
    padding: 12px 0;
}
.member-box h4{
   font-size: 18px;
color: #000;
padding-bottom: 8px;
}

.member-box h5{
   font-size: 13px;
color: #000;
}



footer{
    padding-top: 40px;
    padding-bottom: 20px;
    background: linear-gradient(265.2deg, #0046B8 0%, #0BAEFF 107.45%);
}


.foot-logo{
    margin-bottom: 15px;
}
.footer-info h4{
    font-size: 22px;
    color: #FFF;
    font-family: "Lufga-Medium";
    padding-bottom: 15px;
}
.footer-links li{
    padding-bottom: 10px;
}
.footer-links li:last-child{
    padding-bottom: 0;
}
.footer-links li a{
   font-size: 16px;
line-height: 28px;
color: #FFF;
}

.footer-social{
    display: flex;
    align-items: center;
    margin-bottom: 9px;padding: 16px 0 0 0;
}
.footer-social li{
    padding: 0 7.5px;
}

.footer-bottom{
  font-size: 14px;
line-height: 36px;
color: #FFF;margin: 30px 0 0 0;
text-align: center;
font-family: 'Lufga-Medium';
}


.eco-system h2 {
text-transform: uppercase;
font-weight: 600;
background: #82B7E5;
background: linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
  background-clip: border-box;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin: 0 0 30px 0;
font-size: 48px;
color: #000;
font-family: "Lufga-Bold";
display: block;
text-align: center;
}

.blurEffect > :not(.team-item-li) {
  filter: blur(5px);
}

.teamlist li {
  padding: 15px;
  transition: 0.25s all ease-in-out;
  filter: blur(0) !important;
}

.teamlist li:hover {
  cursor: pointer;
  background: linear-gradient(103.27deg, rgba(120, 206, 255, 0.2) 5.27%, rgba(2, 149, 250, 0.2) 63.42%);
}

.teamlist .menu-item-active {
  filter: blur(0);
}

.teamlist .memberInfo {
  display: flex;
  /*visibility: hidden; */  position: relative;
  /* display: flex; */
  justify-content: space-between;
  display: none;
}


.teamlist li:hover .memberInfo {
  display: block;
}

.teamlist li:hover .memberInfo a img {
  top: 10px;
}


.memberInfo a img {
  position: absolute;
  top: -30px;
  left: 0;
}

.memberInfo p {
  display: flex;
    color: #0046B8;
    align-items: center;
    align-content: center;
}

.memberInfo img {
  width: 25px !important;
  border-radius: 0 !important;
}

.teamlist li:hover .memberInfo {
  /* display: flex; */
  visibility: visible;
  color: red;
}

.teamlist li:hover .left-arrow {
  display: none;
}

.teamlist {
  display: flex;
  margin:30px 0 0 0;
  padding: 0;
  width: 100%;
}


/*.teamlist li:hover {
  background:rgb(35 163 251 / 25%);
}*/


.teamlist li .member-box img {
  border-radius: 10px;
  width: 100%;
}

.member-boxdown {
  height: 80px;
}


.member-box .left-arrow img {
  width: 25px !important;
}


.section-title.text-center.teamheading h4 {
  background: #82B7E5;
  background: -webkit-linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
  background: -moz-linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
  background: linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;margin: 0 0 10px 0;
padding: 0;
}


.teamlist.teamnew {
  margin: 0;
}


.gallery {
  margin:80px 0 80px;
}




.bluebox{
       width: 100%;
    height: 280px;
    background-image: url("../images/blue-frame.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
align-items: center;
justify-content: center;
display: flex;margin: 40px 0 0 0;position: relative;
}


.our-team .container {
  background: transparent;
  box-shadow: inherit;
  border-radius: 0;
}


.blueboxinn {
  background: #FFF;
border-radius: 20px;
padding: 30px;
width: 60%;
margin: 0 auto;
display: block;z-index: 2;
position: relative;
}



.blueboxinn h3 {
  float: left;
  line-height: 45px;
  padding-bottom: 0px;
  font-weight: 600;
  background: #82B7E5;
  background: linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
    background-clip: border-box;
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 0px 0;
  font-size: 31px;
  color: #000;
  font-family: "Lufga-Bold";
  display: block;
}



.bluebox::before {
  content: "";
  width: 100%;
  height: 270px;
   background-image: url("../images/backgrounds/line1.png");
 background-repeat: no-repeat;
background-position: center;
position: absolute;
left: 0;
bottom: 5px;
background-size: 100% 100%;
z-index: 2;
overflow: hidden;

animation: MoveUpDown 5s linear infinite;
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
}



.bluebox::after{
content: "";
width: 100%;
height: 150px;
 background-image: url("../images/backgrounds/line2.png");
background-repeat: no-repeat;
background-position: center;
position: absolute;
left: 0;
bottom: 3px;
background-size: 102% 100%;
z-index: 0;
animation: MoveDownUp 5s linear infinite;
}

@keyframes MoveDownUp {
  0%, 100% {
    bottom: 30px;
  }
  50% {
    bottom: 0px;
  }
}




.banner-text p {
  display: block;
  color: #fff;
  font-size:16px;
  font-weight: 400;
  margin: 0 0 10px 0;
}




.modal-body.popcontentinn.videoinn {
padding: 0;
border-radius: 30px;
}

.no-margin {
  margin: 0 auto;
}


.cookiepolicy{
    color: #0046B8;
}





.box-text.blackboxtxt {
/*  top: 0;
  display: flex;
  justify-content: initial;
  align-items: first baseline;
  height: 100%;*/
}


.box-text.blackboxtxt h3 {
  float: right;
  bottom: 50px;
  position: absolute;
  right: 30px;
}

.blackboxtxtinn h3 {
  float: left !important;
  left: 30px !important;
}



.brand-logo img {
  width: 170px;
}


.foot-logo img {
  width: 170px;
}

/*
section{
	position: absolute;
	display: none;
}


section.current{
	display: block !important;
}*/

.current{
    animation: shrink 3s normal forwards;
}

@keyframes shrink {
    0% { margin-left: 0px; margin-top: 0px; border-radius: 0px; }
    100% { margin-left: 200px; margin-top: -600px;  border-bottom-left-radius: 100px; }
}


.currentBack{
    animation: shrinkBack 3s normal forwards;
}

@keyframes shrinkBack {
    0% { margin-left: 200px; margin-top: -600px; border-radius: 100px; }
    100% { margin-left: 0px; margin-top: 0px;  border-bottom-left-radius: 0px; }
}




#staticBackdrop {
  background: #ecf1f8;
}

#close_btn_register {
  right: 0;font-size: 21px;z-index: 99;
}




.metamstbtn {
display: inline-block;
font-size: 16px;
line-height: normal;
border-bottom: 1px solid #0396fa;
background: linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
  background-clip: border-box;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}


.xinfinbtn{
display: inline-block;
font-size: 16px;
line-height: normal;
border-bottom: 1px solid #0396fa;
background: linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
  background-clip: border-box;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

}


.metamstbtnup {
  font-size: 16px;
}
.twobtnwallet.form-group p {
  margin: 0 0 10px 0;
}



.homefeild {
  text-align: left;
  display: flex;margin: 0 0 15px 0;
}

/*.form-group {
  width: 50%;
}
*/

.homefeild input {
  height: 46px;
}


.homefeild input p::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 300px;
  height: 240px;
  transform: translate(-50%, -50%) rotate(95deg);
  transition: transform .7s linear;
  background: #eb4a26;
  background: linear-gradient(to right, rgb(235 74 38 / 44%) 40%,rgb(164 23 212 / 44%) 50%,rgb(0 70 184 / 44%) 62%);
}


.homefeild .inputboxupp {
  margin: 0;width: 100%;
}


.homefeild .form-group {
  width: 50%;
}


.text-danger.errorebox {
  text-align: center;
}

.homefeildnew input {
position: relative;
overflow: hidden;
color: #fff;
padding: 11px 10px;
cursor: pointer;
font-size: 18px;
margin: 0px;
z-index: 0;height: 48px;
border-radius: 10px;  z-index:9;
border: 0;
background: rgba(255, 255, 255, 0.18);
}

.homefeildnew button {
  height: 44px;
}


.homefeildnew .form-group span {
  content: '';
  position: absolute;
 
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius:10px;
  padding: 3px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0) padding-box;
  mask-composite: add, add;
  mask-composite: add, add;
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}


.homefeildnew .form-group span::before {
  content: '';
  position: absolute;
  left:0%;
  top:0%;
  width: 100%;
  height: 240px;

  transition: transform .7s linear;
  background: #eb4a26;
  background: linear-gradient(to right, rgb(235 74 38 / 44%) 40%,rgb(164 23 212 / 44%) 50%,rgb(0 70 184 / 44%) 62%);
}


.homefeildnew {
  text-align: left;
 /* display: flex;*/
  margin: 0 0 15px 0;
}


.homefeildnew input::placeholder {
  color: #FFF !important;
}


.homefeildnew .form-group {
  width: 100%;
position: relative;
height: 100%;
}

.citizensmain img {
  margin: -50px 0 0 -10%;
}

.changebig {
  font-size: 20px !important;
}



.blink-soft {
  animation: blinker 1.5s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}





.homefeildnewinn {
  display: inline-block;
  width: 50%;
  margin: 0 0 15px 0;
}


.captchasec div {
  width: auto !important;
  display: block !important;
  text-align: center;
  margin: 0 auto;
}


.captchasec {
  margin: 15px 0 0 0;
}


.headervid {
width: 100%;
    height: 526px;
    box-shadow: 0 0 0 0 rgb(255 255 255);
    -webkit-animation: pulse 2s infinite;
    margin: 0px 0 20px 0;
    border-radius: 25px;object-fit: cover;
}


.newvid button {
  display: block;
  margin: 0 auto;
}


.banefits-box-type-3 h4 {
  position: absolute;
  top: 10px;
}


.banefits-box-type-3 div {
  display: block;
  text-align: right;
  width: 100%;
  float: right;margin: 130px 0 0 0;
}

.banefits-box-type-3 h3 {
  text-align: right;
}




.cta h2 {
  background: #82B7E5;
  background: linear-gradient(to right, #82B7E5 0%, #1c86e1 100%);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 20px 0;
  font-size: 48px;
  color: #000;
  font-family: "Lufga-Bold";
  display: block;
  text-align: center;
}


.cta p {
  font-size: 18px;
  line-height: 24px;
  color: #000;
  text-align: center;
  margin: 0 0 30px 0;padding: 0;
}


.our-team p {
  padding: 0;
  margin-bottom: 25px;
}