@media screen and (min-width:768px) {
	button.toggle_btn{display: none;} 
}


@media screen and (max-width: 1400px) {
	
}

/* Large devices (desktops, less than 1200px) */
@media screen and (max-width: 1199px) {
      #trailerVideo {
  height: 420px !important;
  border-radius: 20px;
}



}


/* Medium devices (tablets, less than 992px) */
@media screen and (max-width: 991px) {

      #trailerVideo {
  height: 260px !important;
  border-radius: 20px;
}

	
	
}

/* Small devices (landscape phones, less than 768px) */

@media screen and (max-width: 767px) {
    p{font-size: 14px;line-height: 21px;}
    nav.main-navigation ul li a { color: #fff; font-size: 16px;border-bottom: 1px solid #262525; }
    nav.main-navigation { position: fixed;height:100%; background: #1e1e1e; left: 0; right: 0; top: 56px;opacity:0;visibility: hidden;padding:20px 0;transition: all 0.4s; }
    nav.main-navigation ul { flex-direction: column; }
    .banner-text h1{/*font-size:43px;line-height: 50px;*/
      font-size: 33px;
line-height: 34px;
padding-bottom: 10px;

    }

  /*  .hero-bannner{height:450px;padding-top:140px;}*/
  
    .banner-text .button-blue{margin-left: 0;margin-top: 20px;display: block;}
    .cta{padding: 20px 15px;}
    .cta-text{padding: 30px 25px;z-index: 2;position: relative;background: rgb(255,255,255,0.6);}
    .cta-text h4 { font-size: 20px; line-height: 28px;padding-bottom: 15px;max-width: 100%;}
    .cta-text h3 { font-size:24px; line-height:32px;padding-bottom: 15px;}
    .cta-text p{padding-bottom: 20px;}
    .cta-media { margin: 0; }
    .cta-media img { max-width: 100%; transform: scale(2.0) translate(-5px, -70px); }
    .cta-container{overflow: hidden;position: relative;}
    .cta-container::after{bottom: -140px;}
    .cta-container::before{bottom: 30px;}
    .banefits{padding-top: 30px;padding-bottom: 40px;}
    .banefits-box-type-1{padding: 30px 20px;}
    .banefits-box-type-2 {height: 260px;
background-size: cover;}
    .banefits-box h4 { font-size: 16px !important; line-height: 28px; padding-bottom: 0; }
    .banefits-box h4 br{display: none;}
    .banefits-box h2{font-size: 20px;line-height: 32px;}
    .banefits-box-type-1 { height: 420px; background-size: 100% 100%;display: flex; align-items: flex-end; }
    .left-arrow{width:30px;}

    .banefits-box:hover {
 background-size: initial;
}


    .banefits-box{margin: 0 0 30px;}
    .gallery-section-title h2{font-size:28px;padding-bottom:30px;}
    section.gallery { padding: 30px 0 30px; }
    .gallery-section-title h4 { font-size: 20px; line-height:26px;padding-bottom:10px; }
    .gallery-item{flex-wrap: wrap;flex-direction: column-reverse;}
    .gallery-list .gallery-item .gallery-text, .gallery-item .gallery-img { flex: 0 0 100%;text-align: center; max-width: 100%; padding: 20px 25px; }
    .gallery-item .gallery-text p { font-size: 20px; line-height:26px;text-align: center; color: #000; }
    .gallery-item .gallery-text{border-radius:0 0 30px 30px;margin: 0;}
    .gallery-item .gallery-img { padding: 0; border-radius: 30px 30px 0 0;font-size: 0; }
    .gallery-list .gallery-item:nth-child(even) { flex-direction: column-reverse; }
    .gallery-list .gallery-item:nth-child(even) .gallery-text { border-radius: 0 0 30px 30px; }
    .gallery-item{margin-bottom: 30px;}
    .eco-system{padding: 30px 0;}




    .ecoinn{
      margin: 0 15px;
    }
    .partner-list{justify-content: center;}
    .partner-list li{flex: 0 0 33%;
max-width: 33%;
padding: 10px;}
    .eco-system .container{padding: 30px 30px 15px;}
    .our-team { padding-top:30px; padding-bottom: 0px;margin: 0 15px 0px; }
    .section-title h4{font-size:34px;margin: 0;}
    .our-team .section-title{margin-bottom: 20px;}
    .member-box figcaption{text-align: center;padding: 12px 0 0;}
    .member-box figcaption h5{padding-bottom: 0;}
    .member-box h4 {font-size: 14px;
line-height: initial;
padding-bottom: 10px;
height: 60px; }
    .member-box { margin-bottom:0px; }
    .footer-info h4{font-size: 20px;padding-bottom: 15px;padding-top: 20px;}
    .footer-links li a { font-size: 16px; line-height: 20px;}
    .footer-social{margin: 10px 0 10px;
    display: inline-block;}

    .footer-social li a {
  width: 23px;
}

.footer-social li {
    float: left;
}

.footer-info {
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
}
    .footer-social li a { width:28px; display: inline-block; }
    .footer-bottom{font-size: 18px;margin: 20px 0 0;text-align: left;}
    .brand-logo { max-width: 120px; }


    .sticky button.toggle_btn {
  border: 2px solid #000;
}


.sticky button.toggle_btn span {
 
  background: #000;

  }
 
    .header-right button{font-size: 15px;height: 36px;line-height:29px;}
    button.toggle_btn{background: none;
border: 2px solid #fff;
padding: 5px 7px;
border-radius: 40px;
width: 38px;
height: 38px;
text-align: center;}
    button.toggle_btn span {border-radius:15px;display:block; height: 2px; width: 20px; background: #fff; border: none;
     outline: none; box-shadow: none;margin: 5px 0 0;transition: all 0.4s;}
    header.active button.toggle_btn span:nth-child(1){transform: rotate(45deg) translate(3px, 3px)}
    header.active button.toggle_btn span:nth-child(2){display: none;}
    header.active button.toggle_btn span:nth-child(3){transform: rotate(-45deg) translate(2px, -4px);}
    button.toggle_btn span:first-child{margin: 0;}
    .header-right { margin: 0 2px 0 auto; }
    .gallery-section-title > img { max-width: 200px; }
    .header-right { margin: 0 2px 0 auto}


      .button-file.btnjoin {
  display: none;
}


   /* .main-content { margin-top: 55px; }*/
    .play-btn{font-size: 20px;}
    header.active nav.main-navigation{opacity: 1;visibility: visible;}
    .banefits-box h3{font-size: 20px;}
.modal-content.popcontent .btn-close {
  right: 5px !important; top: 5px !important;z-index: 9999 !important;
}

.topheaderone {
  width: 100%; 
}


.box-text.blackboxtxt h3 {
  right: auto;left: auto !important;
}


.left-arrow, .right-arrow {
  display: inline-block;
  width: 40px;
  display: none;
}

.memberInfo img {
  margin: 6px 0 0;
}


.banefits-box-type-2 .box-text {
  padding: 20px 15px;
}


.hero-bannner{
    width: 100%;
    height:100vh;
    background-image: url("../images/paris-front.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
width: 100%;
align-items: center;
justify-content: center;
}




.gallery-section-title p {
  font-size: 22px;
  max-width: inherit;
}


.networkmain {
  margin: 20px 0 20px 0;
}

.networkmain li {
  padding-bottom: 20px;
  font-size: 41px;
}

.gallery-text h2 {
  margin: 0px 0 0px 0;
}

.gallery-text a {
  margin: 10px 0 0 0;
}

.metaliving {
  text-align: center;
}

.metalivingright img {
 width: 70%;
margin: 0px auto 0;
display: block;
float: right;
}

.gallery-text a img {
  float: none;
  margin: 0px 0;
}

.progressmain {
  width: 100%;
}

.blueboxinn {
  width: 100%;text-align: center;
}

.blueboxinn h3 {
  float: none;
  font-size: 60px;
line-height: 80px;
}

.bluebox {
  height: 450px;border-radius: 20px;
}


.teamlist {
  display: inline-block;
}

/*.teamlist li {
width: 33%;
margin: 0px;
float: left;
}*/

.member-boxdown {
  height: 110px;
}

#staticBackdrop {
  padding: 0px !important;
}

.citizensmain {
  display: none;
}

.frameone {
  display: none;
}

.pyramidright img {
  width: 85%;
  float: right;
}


#close_btn_registerone {
 bottom: 0 !important;
left: auto !important;
top: 0px;
right: -0px;
}

.section-title.text-center.teamheading h4 {
  margin: 30px 0 30px 0;
}


.gapside {
  margin: 0 15px 30px;
}

footer {
  border-radius: 20px;margin: 30px 0 0 0;
}

.lastinfo {
  padding-top: 20px !important;
}

.metalivingleft {
  text-align: left;
}

.col-md-4.hidecude {
  display: none;
}

.metalivingleft {
  text-align: left;
  position: relative;
}

.metalivingright.showcube {
  width: 80%;
  display: block;
  float: right;
}
.showcube {
  position: absolute;
  bottom: -50px;
  right: -40px;
}


.newbenifit {
  display: block !important;
}


.metalivingright.showcube {
  display: block;
}


.banefits{
  display: none;


}


#trailerVideo {
  height: 260px !important;
  border-radius: 20px;
}

.topheader {
  width: 100%;
}


.homefeildnew .form-group {
  width:100%;
  margin: 0 10px 0 0;
}

.homefeildnewinn {
  width: 70%;
}

.captchamain {
  margin: 20px 0 0 0;
}

.homefeildnew .button.button-blue {
  margin: 0;
  height: 48px;
}


}

/* Extra small devices (portrait phones, less than 576px) */

@media screen and (max-width: 575px) {

}


/* Extra small devices (portrait phones, less than 576px) */

@media screen and (max-width: 479px) {

  .button-file {
  padding: 11px 22px;font-size: 16px;
  }

    .banner-text h1 {/* font-size: 32px; line-height: 38px;*/
      font-size: 21px;
line-height: 25px;
padding-bottom: 10px;

     }

  /*  .hero-bannner { height: 330px; padding-top: 40px; }*/

.networkmain li {
  font-size: 28px;
  margin: 0 10px;
}

.progress__circle {
  width: 50px;
  height: 50px;
}


#trailerVideo {
  height: 260px !important;
  border-radius: 20px;
}


.homefeildnew .form-group {
  width: 100%;
  margin: 0 10px 0 0;
}




/*.teamlist {
  display: inline-block;
}

.teamlist li {
width: 100%;
margin: 0;
float: left;
padding: 0 40px;
}


.member-boxdown {
  height: auto;margin: 0 0 20px 0;
}*/


}


@media screen and (max-width: 450px) {

/*  .teamlist li {
  width: 50%;
  margin: 0px;
  float: left;
}*/

.teamlist.teamnew {
  margin: 0 10px;
}
	
}



@media screen and (max-width: 359px){
	

}

@media screen and (max-width: 320px){


}